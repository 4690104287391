/* jshint ignore:start */

var elementReveal = {
  delay    : 0,
  duration: 400,
  easing: 'ease',
  distance : '40px'
};
var elementRevealHero = {
  // delay    : 200,
  duration: 400,
  easing: 'ease',
  distance : '40px',
  origin: 'left'
};




jQuery(document).ready(function($){

// Detecting IE
var oldIE;
if ($('html').is('.oldie, .ie9')) {
    oldIE = true;
}

if (!oldIE) {
    window.sr = ScrollReveal();
    sr.reveal('.scrollInAnimation', elementReveal);
    // sr.reveal('.hero .hero__content', elementRevealHero);
}


objectFitImages();

    var maxRangeSmall = 641,
        wWidth = $(window).outerWidth();


    $("#Glide").glide({
        type: "carousel",
        hoverpause: false,
        animationDuration: 1500
    });


    $('.toggle-menu').click(function(e){
        e.preventDefault();
        if( wWidth <= 830 ) {
            if($(this).siblings('.nav').hasClass('nav--active'))
            {
                $('.nav').removeClass('nav--active');
            }
            else {
                $('.nav').removeClass('nav--active');
                $(this).siblings('.nav').addClass('nav--active');
            }
        }
    });

    $('.nav__item--has-sub').click(function(e){
        //e.preventDefault();
        if( wWidth <= 830 ) {
            $(this).find('.nav__sub').toggleClass('nav__sub--active');
        }
    });

    $('.faq__trigger').click(function(e){
            e.preventDefault();
            $(this).siblings('.faq__content').slideToggle();
    });

    $(window).resize(function(){
        wWidth = $(window).outerWidth();
        resetNav(wWidth);
    });


    function resetNav(wWidth) {

        if( wWidth > 830 ) {
            $('.nav').removeClass('nav--active');
            $('.nav__sub').removeClass('nav__sub--active');
        }

    }

});

/* jshint ignore:end */